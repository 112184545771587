import {
  mergeMixins,
  ItemsTable,
  HaystackSearch,
  Item,
} from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { get } from "@cp/utils/objectUtils";

import { bulkActions } from "./taskAssignments";

const fiverMentors = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/mentors`;

export const table = new ItemsTable({
  module: "mentors",
  baseUrl: `${fiverMentors}/autocomplete`,
  headers: [
    {
      text: "Mentor",
      value: "full_name",
    },
    {
      text: "Mentees #",
      value: "mentee_count",
    },
    { text: "Open Tasks", value: "mentor_task_assignment_count" },
  ],
});

export const newMentorModal = new HaystackSearch({
  module: "mentors",
  urlKey: "employees",
  name: "employees",
  params: { filters: { is_mentor: false } },
});
newMentorModal.add(
  modalAddon({
    modalKey: "addMentor",
    open({ dispatch }) {
      dispatch(newMentorModal.keys.fetch);
    },
    close({ dispatch }) {
      dispatch(table.keys.fetch);
    },
  })
);

export const item = new Item({
  module: "mentors",
  baseUrl: fiverMentors,
  name: "mentor",
  initialValue: {
    full_name: "",
    mentees: [],
    created_at: "",
    updated_at: "",
    client_id: "",
    position_id: "",
    location_id: "",
    first_name: "",
    last_name: "",
    email: "",
    personal_email: null,
    cell_phone: "",
    start_date: "",
    last_archived_at: null,
    full_name: "",
    id: "",
    position: "",
    location: "",
    status_id: 1,
    region_id: "",
    region: "",
    position_category_id: "",
    mentor_task_assignment_count: 0,
    mentee_count: 0,
    mentees: [],
  },
});

export const newMenteeModal = new HaystackSearch({
  parent: item,
  module: "newMenteeModal",
  urlKey: "employees",
  name: "employees",
});
newMenteeModal.add(
  modalAddon({
    modalKey: "addMentee",
    open({ dispatch }) {
      dispatch(newMenteeModal.keys.fetch);
    },
    close({ commit }) {
      commit(newMenteeModal.keys.reset);
    },
  })
);

export const mentorTasks = new ItemsTable({
  parent: item,
  module: "mentorTasks",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/task_assignments/mentor`,
  url: "/:id",
  urlTemplate: true,
  headers: [
    {
      text: "✓",
      sortable: false,
      value: "completion",
      width: 60,
    },
    {
      text: "Task",
      value: "task.title",
      sortable: true,
      cellClass: "clickable-cell",
    },
    {
      text: "Details",
      sortable: false,
      value: "explanation",
      cellClass: "text-no-wrap",
      width: "40%",
    },
    {
      text: "For Employee",
      value: "user",
    },
    {
      text: "Due",
      value: "due_by_date",
      cellClass: "text-no-wrap",
      width: 80,
      sortable: true,
    },
    {
      text: "Notes",
      value: "notes",
      sortable: false,
      width: 80,
    },
  ],
  bulkActions: [
    bulkActions.addNote,
    bulkActions.complete,
    bulkActions.cancel,
    bulkActions.extendDueDate,
    bulkActions.addAssignees,
    bulkActions.reAssign,
  ],
});
mentorTasks.add({
  getters: {
    fetchItemsArgs(state, getters, rootState) {
      const id = get(rootState, item.p.s.stateKey + ".id");
      return { id };
    },
  },
});

item.add({ modules: { newMenteeModal, mentorTasks } });

export default mergeMixins(table, newMentorModal, item);
