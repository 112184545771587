import {
  mergeMixins,
  Table,
  Item,
  Items,
  HaystackSearch,
  ItemFormModal,
} from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { get, deepMerge } from "@cp/utils/objectUtils";
import { unique } from "@cp/utils/arrayUtils";
import { required } from "@cp/utils/rules";

const module = "employees";
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;
const userUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/users`;
const enrollmentsUrl = `${fiverUrl}/enrollments/autocomplete`;
const userTasksUrl = `${fiverUrl}/user_tasks/autocomplete`;

export const employeesTable = new Table({
  module,
  name: "employees",
  noun: "employee",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/employees/autocomplete`,
  compoundId: true,
  initialMeta: {
    filter_options: {
      lifecycle_statuses: { values: [] },
      locations: { values: [] },
    },
  },
  tableOptions: {
    sort_by: "users.first_name",
  },
  headers: [
    {
      text: "Employee Name",
      sortable: true,
      value: "users.first_name",
      cellClass: "clickable-cell",
    },
    {
      text: "Job Title",
      sortable: false,
      value: "position",
    },
    {
      text: "Location",
      sortable: false,
      value: "location",
    },
    {
      text: "Status",
      sortable: false,
      value: "user_lifecycle_statuses_id",
    },
  ],
  filters: [
    "q_text",
    {
      type: "radio",
      key: "general",
      items: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Archived",
          value: "archived",
        },
      ],
    },
    {
      type: "sub_query",
      label: "Region",
      key: "regions",
      multiple: true,
    },
    {
      type: "sub_query",
      label: "Location",
      key: "locations",
      multiple: true,
    },
    {
      type: "sub_query",
      label: "Position",
      key: "positions",
      multiple: true,
    },
    {
      type: "sub_query",
      label: "Status",
      key: "lifecycle_statuses",
      multiple: true,
    },
  ],
  exportOptions: { htmlToCanvas: "#employees-table" },
});

window.$employees = employeesTable;

class EmployeeStatus extends Item {
  constructor(config) {
    super(config);

    this.enrollments = new Items({
      module: "enrollments",
      parent: this,
      baseUrl: enrollmentsUrl,
      params: { page: { size: "all" }, filter: { status: [1] } },
      initialValue: [],
    });
    this.userTasks = new Items({
      module: "userTasks",
      parent: this,
      baseUrl: userTasksUrl,
      params: { page: { size: "all" } },
      initialValue: [],
    });

    this.add({
      state: {
        employeeId: "",
      },
      modules: {
        enrollments: this.enrollments.toVuex,
        userTasks: this.userTasks.toVuex,
      },
    });
  }

  fetch(ctx, { id }) {
    ctx.state.employeeId = id;
    super.fetch(ctx, { id });
    ctx.dispatch(
      this.enrollments.p.a.fetch,
      { params: { filters: { users: [id] } } },
      { root: true }
    );
    ctx.dispatch(
      this.userTasks.p.a.fetch,
      { params: { filters: { employees: [id] } } },
      { root: true }
    );
  }
}

const statusPage = new EmployeeStatus({
  module,
  baseUrl: userUrl,
  compoundId: true,
  initialValue: {
    avatar: {
      url: "",
      thumb: { url: "" },
    },
    email: "",
    full_name: "",
    location: "",
    position: "",
    managers: [],
  },
});

export const addMentorModal = new HaystackSearch({
  parent: statusPage,
  module: "addMentorModal",
  urlKey: "employees",
  name: "mentors",
});
addMentorModal.add({
  getters: {
    mentorsParams(state, getters, rootState) {
      const stateParams = get(state, this.keys.params);
      const not_ids = get(rootState, "employees.item.mentors", []).map(
        x => x.value
      );
      return deepMerge({
        stateParams,
        filters: { is_mentor: true, not_ids },
      });
    },
  },
});
addMentorModal.add(
  modalAddon({
    modalKey: "addMentor",
    open({ dispatch }) {
      dispatch(addMentorModal.keys.search);
    },
    close({ dispatch, commit }) {
      commit(addMentorModal.keys.reset);
      dispatch(statusPage.keys.fetch);
    },
  })
);

export const editEmployeeModal = new ItemFormModal({
  parent: statusPage,
  module: "editEmployeeModal",
  baseUrl: userUrl,
  url: "/:id",
  urlTemplate: true,
  initialValue: {
    client_id: "",
    position_id: "",
    position: "",
    location_id: "",
    location: "",
    user_status_id: 1,
    first_name: "",
    last_name: "",
    email: "",
    personal_email: "",
    cell_phone: "",
    start_date: "",
    locale: "en",
    last_archived_at: null,
    full_name: "",
    created_at: "",
    updated_at: "",
    role_ids: [],
    managers: [],
    manager_ids: [],
    mentor_ids: [],
    mentors: [],
    reference_id: "",
    status_id: 1,
    archived_at: null,
    client_is_admin: false,
    opt_out: null,
    client_has_areas: true,
    transformations: [],
    service_access: [],
    region: {},
    avatar: {
      url: "",
      thumb: {
        url: "",
      },
    },
    deficiencies: {},
    is_mentor: false,
  },
  fields: {
    start_date: { rules: [required] },
    location_id: { rules: [required] },
    position_id: { rules: [required] },
  },
});

export const positionsSearch = new HaystackSearch({
  parent: editEmployeeModal,
  module: "positionsSearch",
  name: "positionsSearch",
  urlKey: "positions",
});

export const locationsSearch = new HaystackSearch({
  parent: editEmployeeModal,
  module: "locationsSearch",
  name: "locationsSearch",
  urlKey: "locations",
  params: { filters: { only_mine: true } },
});
editEmployeeModal.add({ modules: { positionsSearch, locationsSearch } });

statusPage.add({ modules: { addMentorModal, editEmployeeModal } });

export default mergeMixins(employeesTable, statusPage, {
  getters: {
    employeeEnrollments(state) {
      const enrollments = get(state, "enrollments.items", []);
      return enrollments.map(x => {
        const tasks = state.userTasks.items.filter(task => {
          const p_ids = unique(task.plans.map(y => y.id));
          return p_ids.includes(x.plan_id);
        });

        return { ...x, tasks };
      });
    },
  },
});
