import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-loading',{attrs:{"loading":_vm.loading}},[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'mentors' }}},[_vm._v("Mentors")]),_c('span',{staticClass:"mx-2"},[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.item.full_name)+" ")]},proxy:true}])},[_vm._v(" Mentor Details ")]),_c(VCard,{staticClass:"mt-1"},[_c(VCardText,[_c(VRow,{staticClass:"summaryRow align-center"},[_c(VCol,{staticClass:"flex-grow-0"},[_c('div',{staticStyle:{"width":"100px"}},[_c('cp-profile-avatar',{attrs:{"profile":_vm.item}})],1)]),_c(VCol,[_c('table',{staticClass:"summary-table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.item.full_name))])]),_c('tr',[_c('td',[_vm._v("Position")]),_c('td',[_vm._v(_vm._s(_vm.item.position))])]),_c('tr',[_c('td',[_vm._v("Location")]),_c('td',[_vm._v(_vm._s(_vm.item.location))])]),_c('tr',[_c('td',[_vm._v("Email")]),_c('td',[(_vm.item.email)?_c('a',{attrs:{"href":("mailto:" + (_vm.item.email))}},[_vm._v(_vm._s(_vm.item.email))]):_c('span',[_vm._v("missing")])])]),_c('tr',[_c('td',[_vm._v("Cell")]),_c('td',[(_vm.item.email)?_c('a',{attrs:{"href":("tel:" + (_vm.item.cell_phone))}},[_vm._v(_vm._s(_vm.item.cell_phone))]):_c('span',[_vm._v("missing")])])])])])])],1)],1)],1),_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.mentees.length)+" total ")]},proxy:true},{key:"rightOfTitle",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":_vm.openModal}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1)]},proxy:true}])},[_vm._v(" Mentees ")]),_c('new-mentee',{attrs:{"mentor":_vm.item}}),_c(VCard,[_c(VDataTable,_vm._b({scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.start_date",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.toMDY(value)))]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('span',{attrs:{"title":item.email}},[_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-email-outline")])],1)]):_vm._e(),(item.cell_phone)?_c('span',{attrs:{"title":item.cell_phone}},[_c('a',{attrs:{"href":("tel:" + (item.cell_phone))}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-phone-outline")])],1)]):_vm._e()]}}])},'v-data-table',_vm.menteeTableProps,false))],1),_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(_vm._s(_vm.meta.pagination.records)+" total")]},proxy:true},{key:"rightOfTitle",fn:function(){return [(_vm.selected.length)?_c('cp-table-bulk-actions-menu',_vm._b({on:{"closed":_vm.fetchItems}},'cp-table-bulk-actions-menu',_vm.bulkActionsMenuProps,false)):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" Mentor Tasks ")]),_c(VCard,[_c('task-assignments-table',_vm._g(_vm._b({on:{"click:row":function (ref) {
	var id = ref.id;

	return _vm.openTaskModal({ id: id });
}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.on_behalf_of.full_name)+" ")]}}]),model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'task-assignments-table',_vm.tableProps,false),_vm.tableListeners))],1),_c('task-assignment-status-form',{on:{"closed":_vm.fetchItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }