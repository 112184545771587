<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      @click:row="item => $emit('click:row', item)"
      :no-data-text="
        `0 tasks ${
          filterCount ? `match those ${filterCount} filters` : 'found'
        }`
      "
      class="clickable-rows"
      disable-pagination
      v-on="$listeners"
    >
      <template #item.completion="{ item }">
        <cp-explain>
          <template #activator="{on}">
            <div v-on="on">
              <task-progress-cell v-bind="{ item }" />
            </div>
          </template>
          <task-status-summary-cell v-bind="{ item }" />
        </cp-explain>
      </template>

      <template v-slot:item.explanation="{ item }">
        <task-status-summary-cell v-bind="{ item }" />
      </template>

      <template v-slot:item.task.title="{ item }">
        <cp-explain :close-on-content-click="false" max-width="550" offset-x>
          <template #activator="{on}">
            <span v-on="on">
              {{ item.task.title }}
            </span>
          </template>
          <v-card-title>{{ item.task.title }}</v-card-title>
          <v-card-text>
            <p>
              <strong>Description:</strong>
              <div v-html="item.task.description" class="displayHTML" />
            </p>

            <p>
              <strong>Schedule:</strong>
              {{ item.task.schedule }}
            </p>
            <p>
              <strong>
                Enrolled by
                {{ pluralize("plan", item.plans.length) }}:
              </strong>
              {{ item.plans.map(x => x.title).join(", ") }}
            </p>
            <p>
              <strong>Assigned to:</strong>
              {{ snakeToTitleCase(item.assignment_type) }}
            </p>
          </v-card-text>
        </cp-explain>
      </template>

      <template v-slot:item.notes="{ value }">
        <cp-explain v-if="value && value.length" root-slot>
          <template #activator="{on, attrs}">
            <v-btn v-bind="attrs" v-on="on" icon color="primary">
              <v-icon>mdi-message</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-data-table
              :items="value"
              :headers="notesHeaders"
              hide-default-footer
              dense
            >
              <template #item.created_at="{ value }">
                {{ toMDY(value) }}
              </template>
            </v-data-table>
          </v-card>
        </cp-explain>
      </template>

      <template #item.due_by_date="{value}">
        {{ toMDY(value) }}
      </template>

      <template #item.completed_at="{value}">
        {{ toMDY(value) }}
      </template>

      <template #item.on_behalf_of="{item}">
        {{ item.on_behalf_of.full_name }}
      </template>

      <template #item.completed_by="{item}">
        {{ item.completed_by_name }}
      </template>
      <template #item.assignees="{value}">
        <div v-if="value.length">
          <div v-for="assignee in value" :key="assignee.id" class="text-no-wrap">{{ assignee.name }}</div>
        </div>
        <span v-else class="error--text text-no-wrap">No Assignees</span>
      </template>

      <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"></slot
      ></template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import pluralize from "pluralize";

import { snakeToTitleCase } from "@cp/utils/stringUtils";
import { toMDY } from "@cp/utils/dateUtils";

const notesHeaders = [
  {
    text: "Employee",
    value: "user",
  },
  {
    text: "Date",
    value: "created_at",
  },
  {
    text: "Note",
    value: "note",
  },
];

export default {
  props: {
    filterCount: { type: Number, default: 0 }
  },
  data() {
    return { notesHeaders };
  },
  methods: {
    pluralize,
    snakeToTitleCase,
    toMDY,
    ...mapActions("taskStatusForm", ["openItemFormModal"]),
  }
}
</script>