<template>
  <div>
    <cp-h1>Dashboard</cp-h1>
    <v-row>
      <v-col cols="3">
        <div class="focus-items">
          <div class="text-subtitle-2">Focus Items</div>
          <v-card>
            <v-list class="py-0">
              <v-list-item-group
                v-for="{ label, children } in SO.focus_items"
                :key="`group-${label}`"
              >
                <div class="px-4 py-2">
                  <strong>{{ label }}</strong>
                </div>
                <v-divider />
                <v-list-item
                  v-for="{ label, link, value, explanation } in children"
                  :key="`item-${label}`"
                  :to="link"
                  color="primary"
                  class="focus-item-link"
                  dense
                >
                  <v-list-item-content class="flex-row">
                    <span>
                      {{ label }}
                      <cp-explain
                        v-if="explanation"
                        :activatorProps="{ small: true }"
                        >{{ explanation }}</cp-explain
                      >
                    </span>
                  </v-list-item-content>
                  <v-list-item-action-text>{{ value }}</v-list-item-action-text>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>

        <div class="compliance mt-2">
          <div class="d-flex align-center">
            <div class="text-subtitle-2">Compliance</div>
            <v-spacer />
            <v-btn
              :href="`${bigwig}/report/task-compliance-report`"
              class="font-weight-light"
              color="primary"
              small
              text
            >
              View Report
            </v-btn>
          </div>
          <v-card class="d-flex flex-column">
            <div class="px-4 py-2 d-flex align-center">
              <div class="text-caption">
                Statuses of tasks completed (last 90 days)
              </div>
            </div>
            <v-divider />
            <div class="pa-10">
              <cp-explain>
                <template #activator="{on}">
                  <div v-on="on">
                    <doughnut-pie
                      :height="150"
                      :width="150"
                      v-bind="statusPie"
                    />
                  </div>
                </template>
                <table class="ml-4">
                  <tbody>
                    <template
                      v-for="{ label, color, value } in complianceStatusCounts"
                    >
                      <tr :key="label">
                        <td class="stat-label pr-4">
                          <v-icon :color="color" small>mdi-circle</v-icon>
                        </td>
                        <td class="stat-label">
                          {{ label }}
                        </td>
                        <td class="text-right pl-4">{{ value }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </cp-explain>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="9">
        <div class="tables">
          <div class="text-subtitle-2">Enrolled Employees</div>
          <v-card class="d-flex flex-column justify-start">
            <div>
              <v-tabs
                v-model="currentIndex"
                slider-color="primary"
                background-color="#f1f1f1"
                @change="changeTab"
                fixed-tabs
              >
                <v-tabs-slider />
                <v-tab v-for="{ label, value } in tabs" :key="value">
                  {{ label }}
                </v-tab>
              </v-tabs>
            </div>

            <div class="px-4 py-2">
              <v-text-field
                v-model="qText"
                @input="changeSearch"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
                hide-details
                outlined
                dense
              />
            </div>

            <div class="table-container">
              <v-data-table
                v-bind="tableProps"
                v-on="tableListeners"
                class="clickable-rows"
                hide-default-footer
                @click:row="navigateToEmployee"
              >
                <template #item.progress.complete_percentage="{value}">
                  {{ value || "0" }}%
                </template>
              </v-data-table>
            </div>

            <v-spacer />
            <v-divider />

            <v-pagination
              v-if="!noData"
              class="mt-2 text-center"
              v-bind="paginationProps"
              v-on="paginationListeners"
            />
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { kebabToSentenceCase } from "@cp/utils/stringUtils";
import { get } from "@cp/utils/objectUtils";

import { dashboard } from "@/store/modules/dashboard";

const bigwig = process.env.VUE_APP_BIGWIG_UI_PATH;

const statuses = [
  "Completed Ontime",
  // "On Time", MICHAEL
  "Completed Late",
  // "Late", YOU DONKEY
  "Can't Complete",
  "Abandoned",
  "Canceled",
];
const statusColors = {
  "Completed Ontime": "#61A715",
  "On Time": "#61A715",
  "Completed Late": "#E40000",
  Late: "#E40000",
  "Can't Complete": "#FBAF57",
  Abandoned: "#8B5DCB",
  Canceled: "#CCCCCC",
};

export default {
  mixins: [dashboard.mixin],

  data() {
    return {
      qText: "",
      bigwig,
      currentIndex: 0,
    };
  },

  computed: {
    SO() {
      return get(this.meta, "summary_object", {});
    },
    tabs() {
      return this.table.filterOptions[1].props.items;
    },
    complianceStatusCounts() {
      return get(
        this.meta,
        "summary_object.compliance_chart.stats.0.data.values",
        []
      ).map(x => ({ ...x, color: statusColors[x.label] }));
    },
    statusPie() {
      const data = this.complianceStatusCounts.map(x => x.value);
      const labels = this.complianceStatusCounts.map(x => x.label);
      const backgroundColor = statuses.map(x => statusColors[x]);

      return {
        total: this.complianceStatusCounts.reduce((r, x) => r + x.value, 0),
        chartData: {
          labels,
          datasets: [{ data }],
        },
        chartOptions: {
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: true,
              usePointStyle: true,
            },
          },
          backgroundColor,
          // hoverBackgroundColor: backgroundColor,
          borderWidth: 2,
          cutout: "80%",
        },
      };
    },
  },

  methods: {
    kebabToSentenceCase,
    ...mapActions(dashboard.mp, ["updateItemsFilter"]),
    changeTab(index) {
      const tabs = this.table.filterOptions[1].props.items.map(x => x.value);
      this.updateItemsFilter({ introduce_status: tabs[index] });
    },
    changeSearch(q_text) {
      this.$store.dispatch(this.table.p.a.updateFilter, { q_text });
    },
    navigateToEmployee(item) {
      this.$router.push({
        name: "EmployeeStatus",
        params: { id: item.id },
      });
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => (vm.$store.state.dashboard.itemsTableHasFetched = false));
  },
};
</script>

<style>
.htmlToCanvas .dashboard-card-list .v-list {
  max-height: none;
}
</style>

<style lang="scss" scoped>
.dashboard-layout {
}

.focus-items {
}

.compliance {
}

.tables {
}

.table-container {
}

.v-list-item.focus-item-link {
  font-size: 14px;
  font-weight: 300;
  .v-list-item__content,
  .v-list-item__action-text {
    color: $primary;
  }
}
</style>
